<template>
	<div class="pagination">
		<router-link :to="{ query: {...$route.query, p: prev }}" v-show="p > 1" title="Previous Page">
			<ion-icon  name="caret-back-outline"></ion-icon>
		</router-link>
		<div class="pagination__page">
			Page {{ p }}
		</div>
		<router-link :to="{ query: {...$route.query, p: next }}" v-show="count >= limit" title="Next Page">
			<ion-icon  name="caret-forward-outline"></ion-icon>
		</router-link>
	</div>
</template>

<script>
export default {
	name: 'Pagination',
	props: {
		count: Number,
		limit: Number,
	},
	data: () => ({
		next: 1,
		prev: 1,
	}),
	computed: {
		route() {
			return this.$route
		},
		p() {
			return Number(this.route.query.p || 1)
		},
		// prev() {
		// 	return Math.max(1, (this.p - 1))
		// },
		// next() {
		// 	return this.p + 1
		// },
	},
	watch: {
		count: {
			immediate: true,
			handler: function (val) {
				this.prev = Math.max(1, (Number(this.$route.query.p || 1) - 1) )
				this.next = (Number(this.$route.query.p || 1) + 1)
			},
		},
		$route: {
			immediate: true,
			handler: function (val) {
				this.prev = Math.max(1, (Number(this.$route.query.p || 1) - 1) )
				this.next = (Number(this.$route.query.p || 1) + 1)
			},
		},
	},
}
</script>

<style scoped lang="scss">
.pagination {
	@include modules.gutter('padding');
	align-items: center;
	color: modules.color_('text');
	display: flex;
	justify-content: center;
	line-height: 1;

	ion-icon {
		color: modules.color_('text', 'light');
		cursor: pointer;
		@include modules.fontSize(24px);
		transition: color 150ms linear;

		&:hover {
			color: modules.color_('secondary');
		}
	}

	&__page {
		@include modules.fontSize(15px);
		font-weight: 500;
	}
}
</style>
