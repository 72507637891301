<template>
	<div class="modal modal--organization modal-organization">
		<div class="modal__loading" v-if="loading">
			<loading-spinner />
		</div>
		<div class="modal-organization__form">
			<form-organization :organization="organization" />
		</div>
		<div class="modal-organization__stats">
			<dl>
				<dt>Date Created</dt>
				<dd>{{ organization.dateCreated | date('PPp') }}</dd>
				<dt>Created By</dt>
				<dd>{{ organization.createdByName }}</dd>
				<dt>Date Modified</dt>
				<dd>{{ organization.dateModified | date('PPp') }}</dd>
				<dt>Modified By</dt>
				<dd>{{ organization.modifiedByName }}</dd>
			</dl>
		</div>
	</div>
</template>

<script>
import FormOrganization from '@/components/FormOrganization'
import LoadingSpinner from '@/components/LoadingSpinner'

export default {
	name: 'ModalOrganization',
	components: {
		FormOrganization,
		LoadingSpinner,
	},
	data: () => ({
		loading: false,
	}),
	async mounted() {
		this.loading = true
		if (this.$route.params.orgId) {
			await this.$store.dispatch('organization/get', this.$route.params.orgId)
		}
		this.loading = false
	},
	destroyed() {
		this.$store.dispatch('organization/unset')
	},
	computed: {
		organization() {
			return this.$store.getters['organization/organization']
		},
	},
}
</script>

<style scoped lang="scss">
.modal-organization {
	display: grid;
	grid-template-columns: 2fr minmax(10rem, 1fr);
	min-width: 70vw;
	width: 100%;

	@include modules.media-query-max-width('desktop-xs') {
		grid-template-columns: 1fr;
	}

	dl {
		@include modules.fontSize(14px);
		@include modules.gutter('grid-row-gap', 0.5);
		display: grid;
		grid-column-gap: 0;
		grid-template-columns: 1fr auto;
	}

	dd {
		margin: 0;
		text-align: right;
		padding: 0.5rem;

		&:nth-of-type(odd) {
			background-color: modules.color_('background', 'light');
		}
	}

	dt {
		font-weight: 500;
		padding: 0.5rem;
		text-transform: uppercase;

		&:nth-of-type(odd) {
			background-color: modules.color_('background', 'light');
		}
	}
}
</style>
