<template>
	<div class="list-organizations">
		<div class="list-organizations__heading">
		</div>

		<table-list
			:loading="loading"
			:items="organizations"
		>
			<template slot="header">
				<span
					:class="`organizations-header-item organizations-header-item--${key}`"
					:key="key"
					v-for="(col, key) in columns"
				>
					<component :is="orderBy[key] ? 'a' : 'span'" @click="sort(key)"> {{ col }}</component>
					<ion-icon :name="query.order == 'asc' ? `caret-up` : `caret-down`" v-if="orderBy[key] && orderBy[key] == query.orderBy"></ion-icon>
				</span>
			</template>

			<template slot="item" slot-scope="{ item }">
				<router-link
					:class="`organizations-item organizations-item--${key} organizations-item--${item['status']}`"
					:key="`item_${key}`"
					:to="{ name: 'organization', params: { orgId: item.orgId } }"
					v-for="(col, key) in columns"
					v-html="transform(key, get(item, key))"
					@click.native="$event.stopImmediatePropagation()"
				>
				</router-link>
			</template>
		</table-list>
	</div>
</template>

<script>
import { format, parseISO, formatDistanceToNowStrict } from 'date-fns'
import { get } from 'lodash'
import { OrganizationStatus } from '@/lib/enums'

import TableList from '@/components/TableList'

export default {
	name: 'ListOrganizations',
	components: {
		TableList,
	},
	props: {
		loading: Boolean,
		organizations: {
			type: Array,
			default: () => ([]),
		},
	},
	data: () => ({
		columns: {
			name: 'Name',
			'settings.certNumber': 'Cert Number',
			'settings.snlNumber': 'SNL Number',
			'address.city': 'City',
			'address.state': 'State',
			'settings': `Markup (Int|Ext|Inst)`,
			dateCreated: 'Created',
			status: 'Status',
		},
		orderBy: {
			name: 'name',
			'settings.certNumber': 'cert',
			'settings.snlNumber': 'snl',
			'address.city': 'city',
			'address.state': 'state',
			'dateCreated': 'dateCreated',
			'status': 'status',
		},
		order: 'asc',
	}),
	computed: {
		query() {
			return this.$route.query
		},
	},
	methods: {
		sort(key) {
			if (this.orderBy[key]) {
				const query = {
					...this.$route.query,
					orderBy: this.orderBy[key],
					order: this.$route.query.order == 'asc' ? 'desc' : 'asc',
				}
				this.$router.push({ query })
			}
		},
		transform(key, value) {
			switch(key) {
				case 'dateCreated':
					try {
						if (value) {
							const dt = parseISO(value)
							if (dt && (dt instanceof Date)) {
								value = `<span title="${format(dt, 'Pp')}">${formatDistanceToNowStrict(dt, { addSuffix: true })}</span>`
							}
						}
					} catch (error) {
						console.warn(error, value)
					}
					return value
				case 'status':
					if (!value) return
					return OrganizationStatus[value]
				case 'settings.institutionalMarkup':
					if ( !value ) return
					return `${value}%`
				case 'settings':
					if (!value) return
					let { institutionalMarkup, externalMarkup } = value
					let totalMarkup = Number((Number(institutionalMarkup) + Number(externalMarkup)).toFixed(2))
					let settings = [Number(institutionalMarkup), Number(externalMarkup), (totalMarkup)].filter(f => f).map(f => `${f}%`).join(`|`)
					return settings
				default:
					break
			}

			return value
		},
		get,
	},
}
</script>

<style scoped lang="scss">
.list-organizations {
	&__heading {
		@include modules.gutter('padding-left');
		@include modules.gutter('padding-right');
	}

	::v-deep .table-list {
		&__item,
		&__header {
			border-bottom: 1px solid modules.color_('background', 'medium');
			display: grid;
			grid-template-columns: repeat(4, minmax(auto,1fr)) 80px repeat(3, minmax(auto,1fr));
			grid-template-columns: minmax(auto, 1fr) 100px 100px minmax(auto, 1fr) 80px repeat(3, minmax(1fr, auto));

			@include modules.media-query('phone') {
				grid-template-columns: 1fr 1fr;
			}
		}
	}

	.organizations-header-item {
		@include modules.fontSize(14px);
		font-weight: 600;
		padding: 1em 1.5em;

		@include modules.media-query('phone') {
			padding: 0.5em 1em;
		}
	}

	.organizations-item {
		color: modules.color_('text', 'dark');
		font-weight: 500;
		padding: 1.5em;
		text-decoration: none;

		@include modules.media-query('phone') {
			@include modules.fontSize(15px);
			padding: 0.5em 1em;
		}

		&--pending {
			&.organizations-item--status {
				color: modules.color_('announce', 'text');
			}
		}

		&--removed {
			opacity: 0.7;

			&.organizations-item--status {
				color: modules.color_('alert', 'critical')
			}
		}
	}
}
</style>
