<template>
	<div class="partial partial--organizations organizations">
		<div class="partial__heading">
			<ion-icon name="business-outline" size="large" class="partial__icon"></ion-icon>
			<h1 class="partial__title">Manage Organizations</h1>

			<div class="partial__actions">
				<router-link :to="{name: 'organization' }" class="button">
					<div class="mdc-button__ripple"></div>
					<ion-icon name="add-circle-outline" class="mdc-button__icon" aria-hidden="true"></ion-icon>
					<span class="mdc-button__label">Add Organization</span>
				</router-link>
			</div>
		</div>

		<form class="form partial__form" :class="{'partial__form--visible':formVisible}" @submit.prevent="submitForm(fields)" autocomplete="off">
			<button class="button partial__form-search" @click="formVisible=!formVisible">
				<ion-icon name="search-outline" class="mdc-button__icon" aria-hidden="true"></ion-icon>
				<span class="mdc-button__label">Search</span>
			</button>
			<div class="form-section">
				<div class="form-row form-row--col-3">
					<text-field class="form__field" label="Name" v-model="fields.name" type="text" autocomplete="off">
						<span slot="pre"><ion-icon name="search-outline"></ion-icon></span>
					</text-field>
					<text-field class="form__field" label="Cert Number" v-model="fields.cert" type="text" autocomplete="off">
						<span slot="pre"><ion-icon name="search-outline"></ion-icon></span>
					</text-field>
					<text-field class="form__field" label="SNL Number" v-model="fields.snl" type="text" autocomplete="off">
						<span slot="pre"><ion-icon name="search-outline"></ion-icon></span>
					</text-field>
					<text-field class="form__field" label="City" v-model="fields.city" type="text" autocomplete="off">
						<span slot="pre"><ion-icon name="search-outline"></ion-icon></span>
					</text-field>
					<select-field class="form__field" label="State" v-model="fields.state" :options="states" />
					<div>
						<button type="submit" class="button button--unelevated form__button">Search</button>
						<button type="cancel" class="button form__button button--primary" @click="reset()">clear</button>
					</div>
				</div>
			</div>
		</form>

		<div class="partial__body organizations__body">
			<list-organizations :organizations="organizations" :loading="loading" />
			<pagination :limit="limit" :count="orgCount" />
			<button type="button" class="button form__button button--secondary button--right" @click="report()">export</button>
		</div>

		<modal @close="hideModal" :open="modalVisible" :title="modalTitle">
			<router-view class="main__modal" slot="content" name="pageModal" />
		</modal>
	</div>
</template>

<script>
import AutocompleteField from '@/components/AutocompleteField'
import ListOrganizations from '@/components/ListOrganizations'
import Modal from '@/components/Modal'
import Pagination from '@/components/Pagination'
import SelectField from '@/components/SelectField'
import TextField from '@/components/TextField'
import { OrganizationStatus } from '@/lib/enums'
import stateList from '@/assets/stateList.json'
import { forceDownload } from '@/lib/utils'

export default {
	name: 'PartialOrganizations',
	components: {
		AutocompleteField,
		ListOrganizations,
		Modal,
		Pagination,
		SelectField,
		TextField,
	},
	metaInfo: () => ({
		title: 'Organizations',
	}),
	data: () => ({
		fields: {},
		formVisible: false,
		fromQuery: null,
		limit: 50,
		orgOptions: [],
		orgLoading: false,
		states: stateList,
	}),
	computed: {
		loading() {
			return this.$store.getters['misc/loading']
		},
		modalVisible() {
			return this.$route.name == 'organization'
		},
		modalTitle() {
			return this.$route.meta.modalTitle
		},
		organizations() {
			return this.$store.getters['organization/organizations']
		},
		orgCount() {
			return this.organizations.length
		},
	},
	async mounted() {
		// await this.$store.dispatch('organization/list', { p: this.$route.query.p || 1, limit: this.limit })
	},
	methods: {
		hideModal(clicked) {
			this.$router.replace({ name: 'organizations', query: this.fromQuery })
		},
		async submitForm(fields) {
			const _keys = []
			const _vals = []
			for (const key in fields) {
				if (fields[key]) {
					_keys.push(`keywords.${key}`)
					_vals.push(fields[key])
				}
			}

			let keys = _keys.join(`,`)
			let vals = _vals.join(`,`)

			try {
				if (keys && vals) {
					this.$router.push({ query: { key: keys, op: 'array-contains', value: vals }})
				} else {
					this.$router.push({ query: {}})
				}
			} catch (error) {
				this.$notice(`ERROR: ${error.message || error}`)

			}

		},
		reset() {
			this.fields = {}
			this.submitForm(this.fields)
		},
		async report() {
			const _keys = []
			const _vals = []
			for (const key in this.fields) {
				if (this.fields[key]) {
					_keys.push(`keywords.${key}`)
					_vals.push(this.fields[key])
				}
			}

			let keys = _keys.join(`,`)
			let vals = _vals.join(`,`)
			const exported = await this.$store.dispatch('organization/report', {key: keys, value: vals, op: keys && vals ? 'array-contains' : ''})

			try {
				forceDownload(exported, `Organizations Export.csv`)
			} catch (error) {
				this.$notice(`ERROR: ${error.message || error}`)
			}

		},
	},
	watch: {
		$route: {
			immediate: true,
			handler: async function (to, from) {
				if (from && from.name == 'organizations' && to.name == 'organization') return this.fromQuery = from.query
				if (from && from.name == 'organization'
					&& JSON.stringify(to.query) == JSON.stringify(this.fromQuery)
				) return

				this.$store.dispatch('organization/list', {...to.query, p: to.query.p || 1, limit: this.limit, orderBy: to.query.orderBy || 'name' })
					.catch(error => {
						this.$notice(`ERROR: ${error.message || error}`)
					})

				if (to.query?.key) {
					let _keys = to.query.key?.split(',') || []
					let _vals = to.query.value?.split(',') || []
					_keys.forEach((key, i) => this.fields[key.replace(/keywords\./i,'')] = _vals[i])
				}
			},
		},
	},
}
</script>

<style scoped lang="scss">
.organizations {
	&__body {
		position: relative;
	}

	&__loading {
		background-color: rgba(255, 255, 255, 10);
		top: 80px;
		left: 50%;
		position: absolute;
		transform: translateX(-50%);
	}

	.button {
		&--right {
			display: block;
			margin-left: auto;
		}
	}
}
</style>
